import { Component, Input } from '@angular/core';
import { DtoQuotation } from '@common';

@Component({
    selector: 'app-content-quotation',
    templateUrl: './content-quotation.component.html',
    styleUrls: ['./content-quotation.component.scss'],
    standalone: false
})
export class ContentQuotationComponent {

  //#region @Input/@Output/@ViewChild -----------------------------------------
  @Input() quotation?: DtoQuotation
  //#endregion

  //#region getters/setters ---------------------------------------------------
  public get source(): string {
    return `(${this.quotation?.source})`
  }
  //#endregion
}
