import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DtoConfirmationDialogData } from 'curriculum-site/src/app/core/services/form-data.service';


@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false
})
export class ConfirmationDialogComponent {

  //#region public properties -------------------------------------------------
  public data: DtoConfirmationDialogData;
  public content: string;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(@Inject(MAT_DIALOG_DATA) data: DtoConfirmationDialogData) {
    this.data = data;
    this.content = Array.isArray(data.content) ? data.content.join('') : data.content;
  }
  //#endregion

}
