import { AfterContentChecked, Component, ElementRef, inject, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-content-content',
    templateUrl: './content-content.component.html',
    standalone: false
})
export class ContentContentComponent implements AfterContentChecked, OnDestroy {
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */

  //#region @Input/@Output/@ViewChild -----------------------------------------
  @Input() content: string;
  //#endregion

  //#region private properties ------------------------------------------------
  private elementRef: ElementRef = inject(ElementRef);
  private router: Router = inject(Router);
  private links: Array<any>;
  private routerRegex: RegExp;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor() {
    this.routerRegex = /\/(\S+)\?content=(\S+)(&target=router)/gm;
    this.content = '';
    this.links = new Array<any>();
  }

  ngOnDestroy(): void {
    this.links.forEach((fn: any) => {
      const matches = this.routerRegex.exec(fn.attributes[0]?.value)
      if (matches) {
        fn.removeEventListener('click', this.clickEvent.bind(this, matches[1], matches[2]))
      };
    });
    this.links.length = 0;
  }

  ngAfterContentChecked(): void {
    // find all anchors that have target=router and attach click event listener to them
    const anchors = this.elementRef.nativeElement.querySelectorAll('a');
    this.ngOnDestroy();
    if (anchors) {
      anchors.forEach((anchor: any) => {
        if (anchor.attributes) {
          const matches = this.routerRegex.exec(anchor.attributes[0]?.value)
          if (matches) {
            anchor.setAttribute('href', `/${matches[1]}?content=${matches[2]}`);
            this.links.push(anchor);
            anchor.addEventListener('click', this.clickEvent.bind(this, matches[1], matches[2]))
          }
        }
      });
    }
  }
  //#endregion

  //#endregion private methods ------------------------------------------------
  private clickEvent(route: string, queryParams: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    void this.router.navigate([route], { queryParams: { content: queryParams } });
  }
  //#endregion
}
