import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DtoApiInfo, DtoMenu } from '@common';
import { environment } from 'curriculum-site/src/environments/environment';
import { from, Observable, Subject } from 'rxjs';
import { I18nService } from './i18n.service';
import { versionInfo } from './version-info';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  //#region private properties ------------------------------------------------
  private httpClient: HttpClient;
  private menu: Subject<DtoMenu>;
  //#endregion

  //#region public properties --------------------------------------------------
  public getMenu: Observable<DtoMenu>;
  //#endregion

  //#region Constructor -------------------------------------------------------
  constructor(httpClient: HttpClient, i18nService: I18nService) {
    this.httpClient = httpClient;
    this.menu = new Subject<DtoMenu>();
    this.getMenu = from(this.menu);
    i18nService.languageChanged.subscribe(() => this.loadMenu());
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  public getApiInfo(): Observable<DtoApiInfo> {
    return this.httpClient.get<DtoApiInfo>('api/system/info');
  }

  public getClientVersion(): string {
    return versionInfo.version;
  }

  public getClientEnvironment(): string {
    return environment.production ? '' : environment.environment.toUpperCase();
  }
  //#endregion

  //#region private methods
  private loadMenu(): void {
    this.httpClient
      .get<DtoMenu>('/api/site/menu')
      .subscribe((menu: DtoMenu) => this.menu.next(menu));
  }
  //#endregion
}
