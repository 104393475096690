import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  //#region private properties ------------------------------------------------
  private cookiesExpireAfter: number;
  private usingCookies: BehaviorSubject<boolean>;
  //#endregion

  //#region public static properties: cookie names ----------------------------
  public static COOKIE_NAME_LANGUAGE = 'language';
  //#endregion

  //#region public properties -------------------------------------------------
  public usingCookiesChanged: Observable<boolean>;
  //#endregion
  //#region Constructor & C° --------------------------------------------------
  constructor() {
    this.cookiesExpireAfter = 365;
    this.usingCookies = new BehaviorSubject<boolean>(this.getCookieByName(CookiesService.COOKIE_NAME_LANGUAGE) ? true : false);
    this.usingCookiesChanged = from(this.usingCookies);
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  public getCookieByName(name: string): string | undefined {
    let result: string | undefined = undefined;
    const nameLenPlus = (name.length + 1);
    const theCookie = document.cookie
      .split(';')
      .map(c => c.trim())
      .find(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      });

    if (theCookie)  {
      result = decodeURIComponent(theCookie.substring(nameLenPlus));
    }
    return result;
  }

  public setCookieByName(name: string, value: string): void {
    const expiresOn = new Date();
    expiresOn.setTime(expiresOn.getTime() + (this.cookiesExpireAfter * 24 * 60 * 60 * 1000));
    const expires: string = `expires=${expiresOn.toUTCString()}`;
    document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}`;
    this.usingCookies.next(true);
  }

  public deleteCookies(afterClearMessage: string): void {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const name = cookies[i].split("=")[0]
      document.cookie = name + "=;-1";
    }
    alert(afterClearMessage);
    this.usingCookies.next(false);
  }
  //#endregion
}
