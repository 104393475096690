import { Component, Input, OnInit } from '@angular/core';
import { DtoWordDefinition } from '@common';

@Component({
    selector: 'app-content-word-definition',
    templateUrl: './content-word-definition.component.html',
    styleUrls: ['./content-word-definition.component.scss'],
    standalone: false
})
export class ContentWordDefinitionComponent implements OnInit {

  @Input() wordDefinition!: DtoWordDefinition;

  constructor() { }

  ngOnInit(): void {
  }

}
