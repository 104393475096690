import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiInterceptorService } from './services/api-interceptor.service';
import { ErrorHandlerService } from './services/error-handler.service';


@NgModule({ imports: [CommonModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      /*eslint-disable @typescript-eslint/no-base-to-string */
      /*eslint-disable @typescript-eslint/restrict-template-expressions */
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
      /*eslint-enable @typescript-eslint/no-base-to-string */
      /*eslint-enable @typescript-eslint/restrict-template-expressions */
    }
  }
}
